<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="400"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar
          color="#37474F"
          dark
        >
          <v-toolbar-title>
            Confirm App Users Structure ?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>This action will confirm and create this App Users group across your organization.</p>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="createConfirmed()"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        draggable
        droppable
        class="my-tree-view-style"
      />
      <v-card-actions>
        <v-btn
          color="red darken-3"
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#FF3700"
          @click="create()"
        >
          create
        </v-btn>
      </v-card-actions>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { walkTreeData } from 'he-tree-vue';
  import Constants from 'src/constants';

  export default {
    name: 'GroupAppUsers',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        treeData: [],
        loading: false,
        showModal: false,
        deleteMessage: '',
      };
    },
    computed: {
      createdStructure () {
        return this.$store.getters['appusers/getCreatedAppUsersStructure'];
      },
      savedStructure () {
        return this.$store.getters['appusers/getSavedAppUsersStructure'];
      },
      paginatedAppUsersStructure () {
        return this.$store.getters['appusers/getAppUsers'];
      },
      appUsers () {
        const records = this.paginatedAppUsersStructure;
        return records.data;
      },
    },
    async mounted () {
      this.loading = true;
      await this.getAppUsers();
      await this.$store.dispatch('appusers/fetchSavedAppUsersStructure');
      this.createAppUsersTreeList();
      this.loading = false;
    },
    methods: {
      async getAppUsers () {
        await this.$store.dispatch('appusers/fetchAppUsers', {
          params: {
            params: {
              limit: null,
              startPage: null,
            },
          },
        });
      },
      createAppUsersTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const appUsers = this.appUsers;
          const structure = this.createdStructure;
          for (const appUser of appUsers) {
            structure[0].children.push({
              id: appUser.external_id,
              text: `${appUser.first_name} ${appUser.last_name} - ${appUser.company.name}`,
            });
          }
          this.treeData = structure;
          return;
        }
          this.treeData = this.createdStructure;
          const availableAppUsers = [];
            walkTreeData(this.treeData, (node) => {
              if (node.id !== undefined) {
                availableAppUsers.push(node.id);
              }
            });
          const newAppUsers = this.appUsers.filter(i => !availableAppUsers.includes(i.external_id));
          for (const newAppUser of newAppUsers) {
            this.treeData[0].children.push({
              id: newAppUser.external_id,
              text: `${newAppUser.first_name} ${newAppUser.last_name} - ${newAppUser.company.name}`,
            });
          }
      },
      create () {
        if (this.treeData.length > 1 || this.treeData[0].text !== 'All App Users') {
          this.$store.dispatch('alert/onAlert', {
            message: 'All nodes should come under "All App Users".',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showModal = true;
      },
      async createConfirmed () {
        this.loading = true;
        await this.$store.dispatch('appusers/groupAppUsers', {
          group: this.treeData,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'App users group created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.showModal = false;
          this.$router.push({ name: 'App Users' });
        }).finally(
          this.showModal = false,
          this.loading = false,
        );
      },
      cancel () {
        this.$router.push({ name: 'App Users' });
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
</style>
